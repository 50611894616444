import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store'
import { Provider } from 'react-redux'

if ( !window.location.origin.includes("chatbotlocal") && !window.location.origin.includes("localhost") && !window.location.origin.includes("chatbotnew")) {
  
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  console.trace = function () {};
  window.alert = function () {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode> 
);
reportWebVitals();
