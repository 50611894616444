import React from 'react'
import { Link } from 'react-router-dom'
import WebsiteSetup from '../../scripts/WebsiteSetup'
import { NotificationManager } from 'react-notifications'
import FormBotSetup from '../../scripts/FormBotSetup'
import { trackData } from '../../../utilities/chatbotMethods'
import { IFRAME_URL } from '../../../config/params'

function FormBot() {


            //utility function for copy text
        //copy the given text to clipboard
        const copyToClipboard = (text) => {
        
            navigator.clipboard.writeText(text)
            .then(() => {
            console.log('Text copied to clipboard: ' + text);
            NotificationManager.success('', 'text copied', 2000)
            // You can provide user feedback here (e.g., display a success message)
            })
            .catch((err) => {
            console.error('Unable to copy text: ', err);
            NotificationManager.success('', 'something went wrong', 2000)
            // Handle errors (e.g., display an error message)
            });
        }

            //handler function for copy text`
        const handleCopyText = (text) => {

              //LOGGINT THE ACTIVITY
              //DEF - SETUP CLICKED
              const activityData = {
                setup : {
                  email : localStorage.getItem('email'),
                  setupType : 'formbot',          
                  botType : localStorage.getItem('botType'),
                  botId : localStorage.getItem('botId'),
                  botTypeName : localStorage.getItem('bot_type') 
                  
                }
              }

              console.log(activityData , ' fjgcadshkdlas')

              trackData('FORMBOT_SCRIPT_COPIED' , 'setup' , 'User copied the form bot preview' , activityData).then(res => {
                if(res){
                  console.log('FORMBOT_SCRIPT_COPIED')
                }
              })
            console.log(text ,' kjbhkj')
            copyToClipboard(text)
        }
        
        //getting the text of the script
        const getScript = () => {
            var divText = document.getElementById('myScript2').innerText;
            console.log(divText , ' script');
            return divText
        }

        
        //make html fileb and download file
        const  handleDownloadHtml = () => {


            //LOGGINT THE ACTIVITY
              //DEF - SETUP CLICKED
              const activityData = {
                setup : {
                  email : localStorage.getItem('email'),
                  setupType : 'formbot',          
                  botType : localStorage.getItem('botType'),
                  botId : localStorage.getItem('botId'),
                  botTypeName : localStorage.getItem('bot_type') 
                  
                }
              }

              console.log(activityData , ' fjgcadshkdlas')

              trackData('FORMBOT_SCRIPT_DOWNLOAD' , 'setup' , 'User downloaded the form bot script' , activityData).then(res => {
                if(res){
                  console.log('FORMBOT_SCRIPT_DOWNLOAD')
                }
              })


            console.clear()
            var fileCode = `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=6.0, user-scalable=0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge">
                <meta name="lang" http-equiv="content-language" content="it">
            </head>
            
            <body style="margin: 0" src="${window.location.origin}/widget/newBuild.js">
            <script id="appyWidgetInit"></script>
            </body>
            
            </html>`
            // const fileData = JSON.stringify(jsonData);
            const blob = new Blob([fileCode], { type: 'text/html' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.download = 'index.html'
            link.href = url
            link.click()
          }


          //handler function
          const handlePreviewClick = () => {
              //LOGGINT THE ACTIVITY
              //DEF - SETUP CLICKED
              const activityData = {
                setup : {
                  email : localStorage.getItem('email'),
                  setupType : 'formbot',          
                  botType : localStorage.getItem('botType'),
                  botId : localStorage.getItem('botId'),
                  botTypeName : localStorage.getItem('bot_type') 
                  
                }
              }

              console.log(activityData , ' fjgcadshkdlas')

              trackData('FORMBOT_PREVIEW' , 'setup' , 'User clicked on the form bot preview' , activityData).then(res => {
                if(res){
                  console.log('FORM_BOT_PREVIEW')
                }
              })
          }


          
          


  return (
    
                    <div className="customeWebsite customeWebsiteWidget px-5 py-5">
                      
                      
                      <div className="copyPasteWidget py-5">
                        <h4 className="text-base font-medium">
                          Copy and paste the widget code in the &lt;body&gt; section of each page of your website.
                        </h4>
                        <p className="font-medium text-sm text-[#5269C9] text-right mt-3">
                         <a onClick={handlePreviewClick} className = "cursor-pointer inline-block underline preview" target="_blank" href={`${IFRAME_URL}?companyId=${localStorage.getItem('tablealias')}&botId=${localStorage.botId}&agentId=${localStorage.getItem('agent_id')}`} >Preview</a>
                          {/* <span className="cursor-pointer inline-block underline">Send widget code</span> */}
                        </p>
                        <div className="codeWidget bg-[#F4F5F9] px-5 py-7 flex my-3 relative">
                          <div className="overflow-x-auto min-w-full max-w-xs w-full">
                            <p className="text-[#343434] text-sm">
                            <pre>
                                <code>
                               {/* {pre}
                                */}
                                <FormBotSetup/>
                                </code>
                            </pre>
                            </p>
                          </div>

                          {/* copy text */}
                          <span onClick={() => handleCopyText(getScript())} className="flex items-center justify-center border rounded bg-white w-7 h-7 cursor-pointer ml-auto absolute right-10 top-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="14"
                              viewBox="0 0 15.058 17.436"
                            >
                              <path
                                id="Icon_material-content-copy"
                                data-name="Icon material-content-copy"
                                d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z"
                                transform="translate(-3 -1.5)"
                                fill="#5269c9"
                              />
                            </svg>
                          </span>


                          {/* download html */}
                          <span onClick={() => handleDownloadHtml()}  className="flex items-center justify-center border rounded bg-white w-7 h-7 cursor-pointer ml-auto absolute right-2 top-2">
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#536bde"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#536bde"/>
                            </svg>
                          </span>
                        </div>
                        <p className="text-sm leading-6 text-[#343434]">
                          Note: Once you’ve added the code on your website then you need to reload your website to see
                          the widget code.
                        </p>
                      </div>
                    </div>
  )
}

export default FormBot