import { API_URL } from "../../config/params";
import axios from "axios";

export const getPaymentDetailsAsync = async(_,thunkAPI) => {
	try {
		// let countryCode = "US";

           
		
            if(localStorage.getItem("email")){
               
                var urlParams = new URLSearchParams(window.location.search);

                let appcountry;
                const GET_COUNTRY_FROM_IP_API_URL = "https://snappy.appypie.com/webservices/ClientDetails.php"
                // const ip_details = await axios.get("https://snappy.appypie.com/index/get-visitor-ip");
                const ip_details = await axios.get(GET_COUNTRY_FROM_IP_API_URL);
                if (urlParams.has("appcountry")){
                    appcountry=urlParams.get("appcountry")
                }
                else{
                    appcountry=ip_details.data.CountryCode;
                }
                localStorage.setItem("ip",ip_details.data.ipAddress)
                localStorage.setItem("appcountry",appcountry)
                

                var data = {
                    action: "encrypt",
                    string: {
                        method: "pricing",
                        product: 'chatbot',
                        countryCode: appcountry,
                        email: localStorage.getItem("email"),
                    },
                };
                var urlfb = API_URL + '/payment_conversion' ;
               
            
                const res = await axios.post(
                    urlfb,
                    data
                );
                let normalPlan = res.data;

                console.log(normalPlan,"normalPlan")

                var comboData = {
                    action: "encrypt",
                    string: {
                        method: "pricing",
                        product: "livechatChatbot",
                        countryCode: appcountry,
                        email: localStorage.getItem("email"),
                    },
                };
                var urlfb = API_URL + '/payment_conversion' ;
               
            
                const comboRes = await axios.post(
                    urlfb,
                    comboData
                );
                let comboPlan = comboRes.data;

                console.log(comboPlan,"comboPlan")


                var subscribtiondata = {
                    action: "encrypt",
                    string: `${localStorage.getItem("cognito_id")}####${localStorage.getItem(
                        "email"
                    )}####chatbot`,
                };
        
                // const subRes = await axios.post(
                //     "https://us-central1-appycx-beta.cloudfunctions.net/encryption",
                //     subscribtiondata
                // );
                const subRes = await axios.post(
                    API_URL + '/commonBillingencryption',
                    subscribtiondata
                );

                console.log(subRes.data.string , 'subRes.data.string')


                
                // dispatch({
                //     type: SUBSCRIPTIONURL,
                //     payload: subRes.data.string,
                // });
                return {
                    subscriptionUrl: subRes.data.string,
                    pricingPlan: normalPlan,
                    comboPlan: comboPlan,

                }


                // if(!appType){
                //     dispatch({
                //         type: PAYMENTDETAILS_ORIGINAL,
                //         payload: pd,
                //     });
                //     console.log(res.data,"p123data");
                //     dispatch({
                //         type: PAYMENTDETAILS,
                //         payload: res.data,
                //     });}
                //     else{
                //         dispatch({
                //             type: PAYMENTCOMBODETAILS,
                //             payload: res.data,
                //         });
                //     }
                }   

           
	 

        
	} catch (err) {
        console.log(err)
		throw new Error(err)
	}
};

