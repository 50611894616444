import React from 'react'
import { useEffect , useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../../../config/params'
import { getDeployedTelegramList } from '../../../utilities/chatbotMethods'
import {NotificationManager} from 'react-notifications'
import StepLoader from '../../loaders/StepLoader'
import SetupLoader from '../../loaders/SetupLoader'

function Telegram() {

  const [isLoading , setIsLoading] = useState(true)
  const [telegramToken , setTelegramToken] = useState([])
  const [isInstalled , setIsInstalled] = useState(false)
  const [telegramData , setTelegramData] = useState({})
  const [enable , setEnable] = useState(false)


  useEffect(() => {
    getDeployedTelegramList(localStorage.getItem("botId")).then((res) => {
      if(res.length>0){
        setIsInstalled(true)
        setTelegramToken(res[0].token)
        setTelegramData(res[0])
        setIsLoading(false)
        setEnable(res[0].isActive)
      }else{
        setIsInstalled(false)
        setIsLoading(false)
      }

    })
    
  },[])

  // const installTelegram = async () => {

  
  //     let telegramData = {
  //       token: telegramAccessToken,
  //       cid: localStorage.getItem("tablealias"),
  //       agentId: localStorage.getItem("agent_id"),
  //       botId: window.location.href.split("?")[1].split("=")[1],
  //     }
  //     let installUrl;
  //     if (process.env.NODE_ENV != "production") {
  //       installUrl = "https://us-central1-appycx-beta.cloudfunctions.net/deployTelegram";
  
  //     } else {
  //       // installUrl='http://localhost:5000/chatbot-production-d6ea3/us-central1/deployTelegram'
  //       installUrl = "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/deployTelegram";
  //     }
  //     fetch(
  //       installUrl,
  //       {
  //         method: "post",
  //         body: JSON.stringify(telegramData),
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //         .then(async (res) => res.json())
  //         .then(async (response) => {
  //           console.log("++++++++ Response ++++++++", response)
  //           if (response.success) {
  //             fetchTelegramList(botId)
  //             setLoader(false)
  //             setShowDeactivate(true)
  //             var bodyExtraShdow = document.getElementById("root");
  //             bodyExtraShdow.classList.toggle("bodyExtraShdow");
  //         } else {
  //           setLoader(false)
  //           NotificationManager.error('', 'Invalid bot token', 2000)
  //         }
        
  // })
  // }

  // const enableTelegram = (e, userId) => {
  //   console.log(e.target.checked)
  //   if (e.target.checked) {
  //     uninstallTelegram("enable", userId)
  //   } else {
  //     uninstallTelegram("disable", userId)
  //   }
  // }
  // function uninstallTelegram(action, userId) {
  //   setLoader(true)
  //   let telegramData = {
  //     cId: localStorage.getItem("tablealias"),
  //     agentId: localStorage.getItem("agent_id"),
  //     botId: window.location.href.split("?")[1].split("=")[1],
  //     userId: userId,
  //     action: action
  //   }
  //   let uninstallUrl;
  //   if (process.env.NODE_ENV != "production") {

  //     uninstallUrl = "https://us-central1-appycx-beta.cloudfunctions.net/manage_bots/uninstall-telegram";
  //   } else {
  //     // uninstallUrl = "http://localhost:5000/chatbot-production-d6ea3/us-central1/manage_bots/uninstall-telegram";

  //     uninstallUrl = "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/manage_bots/uninstall-telegram";
  //   }
  //   fetch(
  //     uninstallUrl,
  //     {
  //       method: "post",
  //       body: JSON.stringify(telegramData),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log("++++++++ Response ++++++++", response)
  //       let msg;
  //       if (response.success) {
  //         if (action == "enable") {
  //           msg = "Deployment on telegram has been enabled"
  //           let newList = telegramList.map(el => {
  //             el.isActive = true
  //             return el
  //           })
  //           setTelegramList(newList)
  //         } else if (action == "disable") {
  //           msg = "Deployment on telegram has been disabled"
  //           let newList = telegramList.map(el => {
  //             el.isActive = false
  //             return el
  //           })
  //           setTelegramList(newList)
  //         } else {
  //           setTelegramList([])
  //           msg = "Bot has been disconnected from telegram"
  //         }
  //         setLoader(false)
  //         NotificationManager.success('', msg, 2000)
  //       } else {
  //         NotificationManager.error('', 'Error updating bot status', 2000)
  //       }
  //     })
  // }

  // const Activatepopup = (
  //   <div>
  //     <div className='dashboard-chatbot-cover'></div>
  //     <div
  //       className='deleting-popup-container'>
  //       <div className="deleting-popup-body">
  //         <p> Thanks for providing the access key.Please click on "Start" button in your telegram app.</p>
  //         <div className='ETbuttons mt-4'>
  //           <div className='ETSave' onClick={hidepopup}>
  //             Ok
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  const handleTelegram = (action) => {

    console.log('first')
      setIsLoading(true)
      let body = {
        cId: localStorage.getItem("tablealias"),
        agentId: localStorage.getItem("agent_id"),
        botId: localStorage.getItem("botId"),
        userId: telegramData.user_id,
        action: action
      }
      let uninstallUrl = API_URL + '/manage_bots/uninstall-telegram';
      
      fetch(
        uninstallUrl,
        {
          method: "post",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          console.log("++++++++ Response ++++++++", response)
          let msg;
          if (response.success) {
            if (action == "enable") {
              msg = "Deployment on telegram has been enabled"
              // let newList = telegramList.map(el => {
              //   el.isActive = true
              //   return el
              // })
              // setTelegramList(newList)

              setEnable(true)
              
            } else if (action == "disable") {
              msg = "Deployment on telegram has been disabled"
              // let newList = telegramList.map(el => {
              //   el.isActive = false
              //   return el
              // })
              // setTelegramList(newList)
              setEnable(false)
            } else {
              // setTelegramList([])
              setTelegramData({})
              setIsInstalled(false)
              setTelegramToken('')
              msg = "Bot has been disconnected from telegram"
            }
            setIsLoading(false)
            NotificationManager.success(msg, '', 2000)
          } else {
            setIsLoading(false)
            NotificationManager.error('Error updating bot status', '' , 2000)
          }
        }).catch(err => {
          // debugger
          setIsLoading(false)
            NotificationManager.error('Error updating bot status', '' , 2000)
          }
        
          )
    
    
  }

  

  const installTelegram = () => {
    setIsLoading(true)
    console.log('clicked install telegram')
        let body = {
        token: telegramToken,
        cid: localStorage.getItem("tablealias"),
        agentId: localStorage.getItem("agent_id"),
        botId: localStorage.getItem("botId"),
        email: localStorage.getItem("email"),
      }
      let installUrl = API_URL + '/deployTelegram';
      
      fetch(
        installUrl,
        {
          method: "post",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
          .then(async (res) => res.json())
          .then(async (response) => {
            console.log("++++++++ Response ++++++++", response)
            if (response.success) {
              
                
                  setIsInstalled(true)
                  // setTelegramData(response.telegramObj)
                  // setTelegramData(res[0])
                  // setIsLoading(false)
                  // setEnable(true)
                  getDeployedTelegramList(localStorage.getItem("botId")).then((res) => {
                    if(res.length>0){
                      setIsInstalled(true)
                      // setTelegramToken(res[0].token)
                      setTelegramData(res[0])
                      setIsLoading(false)
                      setEnable(true)
                    }else{
                      setIsInstalled(false)
                      setIsLoading(false)
                    }
              
                  })
              
          } else {
            setIsLoading(false)
            NotificationManager.error('Invalid bot token', '' , 2000)
          }
        
  })
  }

  const toggleTelegramStatus = () => {
    setIsLoading(true)
    if(enable){
      handleTelegram('disable')
      // setEnable(false)
      // setIsLoading(false)
    }else{
      handleTelegram('enable')
      // setEnable(true)
      // setIsLoading(false)
    }
  }
  return (
    <>
    {isLoading?(<SetupLoader/>):
                    (<div className="botWordpress customeWebsiteWidget px-5 py-5">
                      {isInstalled ? <>
                        <div className='flex flex-col bg-[#F4F5F9] border rounded-xl p-4'>
                         
                          <h2 className='text-xl font-semibold mb-4'>{telegramData.first_name?telegramData.first_name:""}</h2>
                          <p>{'https://t.me/' + telegramData.user_name}</p>
                          {/* <input type="button" name="isEnable" id="isEnable" checked={enable} onChange = {(event) => {toggleTelegramStatus(event.target.value)}} /> */}
                         <div className='flex items-center gap-4 mt-5'>
                          <button className=' w-36 bg-[#5371ff] drop-shadow-sm p-2 text-center rounded-3xl font-light text-white whitespace-nowrap hover:drop-shadow-xl' onClick={() => {toggleTelegramStatus()} }>{enable ? 'Disable' : 'Enable'}</button>
                          <button className=' w-36  border border-[#404040] drop-shadow-sm p-2 text-center rounded-3xl font-light text-[#404040] whitespace-nowrap hover:drop-shadow-xl' onClick={() => handleTelegram('uninstall')}>Delete</button>
                         </div>
                        </div>
                      </> : <>
                      <div className="widgetParaGraphGuideLine">
                        <p className="text-sm pb-1">Deploy your bot in your telegram account.</p>
                        <p className="text-sm">
                        Enter the access key (bot token)  
                        <a className='text-blue-900' href='https://snappy.desk.appypie.com/kb/article/how-to-deploy-your-chatbot-on-telegram' target='_blank'> How I get the access key</a>
                        </p>
                      </div>
                      <div className="watchGuideVideo mt-5">
                        {/* {<p></p>} */}
                            <input value={telegramToken} onChange={(event) => setTelegramToken(event.target.value)} type="text" className="border mr-2 py-1 rounded h-9 w-2/4" />
                            <button onClick={() => installTelegram()} className="h-9 border border-indigo-600 text-sm px-5 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white">Enable</button>
                      </div></>}
                      
                    </div>)}
                    </>
  )
}

export default Telegram



